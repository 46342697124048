.nm-wi-a {
  width: auto;
}

.nm-wi-i {
  width: inherit;
}

.nm-wi-80 {
  width: 80%;
}

.nm-wi-100 {
  width: 100%;
}

.nm-wi-100-vh-i {
  width: 100vh !important;
}

.nm-wi-16p {
  width: 16px;
}

.nm-wi-24 {
  width: 24px;
}

.nm-wi-32 {
  width: 32px;
}

.nm-wi-150 {
  width: 150px;
}

.nm-wi-300p {
  width: 300px;
}

.nm-wi-500p {
  width: 500px;
}

.nm-min-wi-16p {
  min-width: 16px;
}

.nm-mi-wi-24 {
  min-width: 24px;
}

.nm-min-wi-100 {
  min-width: 100%;
}

.nm-max-wi-500p {
  max-width: 500px;
}

.nm-he-a {
  height: auto;
}

.nm-he-i {
  height: inherit;
}

.nm-he-80 {
  height: 80%;
}

.nm-he-100 {
  height: 100%;
}

.nm-he-24 {
  height: 24px;
}

.nm-he-32 {
  height: 32px;
}

.nm-he-38 {
  height: 38px;
}

.nm-he-150 {
  height: 150px;
}

.nm-he-300p {
  height: 300px;
}

.nm-he-578 {
  height: 578px;
}

.nm-he-86 {
  height: 86px;
}

.nm-he-100p {
  height: 100px;
}

.nm-max-he-300 {
  max-height: 300px;
}

.nm-mi-he-24 {
  min-height: 24px;
}

@media (max-width: 960px) {
  .nm-he-220p_ma-wi-960p {
    height: 220px;
  }

  .nm-wi-220p_ma-wi-960p {
    width: 220px;
  }

  .nm-wi-vw-90_ma-wi-960 {
    width: 90vw;
  }
}
