@xs: 2px;
@s: 4px;
@m: 6px;
@l: 8px;
@xl: 10px;

// Padding top
.nm-pa-t-xs {
  .paddingTop(@xs);
}

.nm-pa-t-s {
  .paddingTop(@s);
}

.nm-pa-t-m {
  .paddingTop(@m);
}

.nm-pa-t-l {
  .paddingTop(@l);
}

.nm-pa-t-xl {
  .paddingTop(@xl);
}

// Padding right
.nm-pa-r-xs {
  .paddingRight(@xs);
}

.nm-pa-r-s {
  .paddingRight(@s);
}

.nm-pa-r-m {
  .paddingRight(@m);
}

.nm-pa-r-l {
  .paddingRight(@l);
}

.nm-pa-r-xl {
  .paddingRight(@xl);
}

// Padding bottom
.nm-pa-b-xs {
  .paddingBottom(@xs);
}

.nm-pa-b-s {
  .paddingBottom(@s);
}

.nm-pa-b-m {
  .paddingBottom(@m);
}

.nm-pa-b-l {
  .paddingBottom(@l);
}

.nm-pa-b-xl {
  .paddingBottom(@xl);
}

// Padding left
.nm-pa-l-xs {
  .paddingLeft(@xs)
}

.nm-pa-l-s {
  .paddingLeft(@s);
}

.nm-pa-l-m {
  .paddingLeft(@m);
}

.nm-pa-l-l {
  .paddingLeft(@l);
}

.nm-pa-l-xl {
  .paddingLeft(@xl);
}

// Padding xs
.nm-pa-xs {
  padding: @xs;
}

// Padding s
.nm-pa-s {
  padding: @s;
}

// Padding m
.nm-pa-m {
  padding: @m;
}

// Padding l
.nm-pa-l {
  padding: @l;
}

// Padding xl
.nm-pa-xl {
  padding: @xl;
}

.paddingTop(@px) {
  padding-top: @px;
}

.paddingRight(@px) {
  padding-right: @px;
}

.paddingBottom(@px) {
  padding-bottom: @px;
}

.paddingLeft(@px) {
  padding-left: @px;
}

// Padding left
.nm-pa-l-12 {
  padding-left: 12px;
}

// Padding right
.nm-pa-r-0 {
  padding-right: 0;
}

// Padding bottom
.nm-pa-b-16 {
  padding-bottom: 16px;

}

// Padding top
.nm-pa-t-16 {
  padding-top: 16px;
}
