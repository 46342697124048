.nm-shadow-l {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
  0 13px 19px 2px rgba(0, 0, 0, 0.14),
  0 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.nm-shadow-sm {
  box-shadow: 0 8px 11px -4px rgba(0, 0, 0, .2),
  8px 6px 8px -4px rgba(0, 0, 0, .2);
}
