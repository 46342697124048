@xs: 2px;
@s: 4px;
@m: 6px;
@xm: 7px;
@l: 8px;
@xl: 10px;
@xxxl: 18px;

// Margin top
.nm-ma-t-xs {
  .margin-top(@xs);
}

.nm-ma-t-s {
  .margin-top(@s);
}

.nm-ma-t-m {
  .margin-top(@m);
}

.nm-ma-t-l {
  .margin-top(@l);
}

.nm-ma-t-xl {
  .margin-top(@xl);
}

.nm-ma-t-xxxl {
  .margin-top(@xxxl);
}

// Margin right
.nm-ma-r-xs {
  .margin-right(@xs);
}

.nm-ma-r-s {
  .margin-right(@s);
}

.nm-ma-r-m {
  .margin-right(@m);
}

.nm-ma-r-l {
  .margin-right(@l);
}

.nm-ma-r-xl {
  .margin-right(@xl);
}

// Margin bottom
.nm-ma-b-xs {
  .margin-bottom(@xs);
}

.nm-ma-b-s {
  .margin-bottom(@s);
}

.nm-ma-b-m {
  .margin-bottom(@m);
}

.nm-ma-b-xm {
  .margin-bottom(@xm);
}

.nm-ma-b-l {
  .margin-bottom(@l);
}

.nm-ma-b-xl {
  .margin-bottom(@xl);
}

// Margin left
.nm-ma-l-xs {
  .margin-left(@xs)
}

.nm-ma-l-s {
  .margin-left(@s);
}

.nm-ma-l-m {
  .margin-left(@m);
}

.nm-ma-l-l {
  .margin-left(@l);
}

.nm-ma-l-xl {
  .margin-left(@xl);
}

// Margin xs
.nm-ma-xs {
  margin: @xs;
}

// Margin s
.nm-ma-s {
  margin: @s;
}

// Margin m
.nm-ma-m {
  margin: @m;
}

// Margin l
.nm-ma-l {
  margin: @l;
}

// Margin xl
.nm-ma-xl {
  margin: @xl;
}

.margin-top(@px) {
  margin-top: @px;
}

.margin-right(@px) {
  margin-right: @px;
}

.margin-bottom(@px) {
  margin-bottom: @px;
}

.margin-left(@px) {
  margin-left: @px;
}

.nm-ma-b-0 {
  margin-bottom: 0;
}

.nm-ma-t-0 {
  margin-top: 0;
}

.nm-ma-t-minus-10 {
  margin-top: -10px;
}

.nm-ma-16 {
  margin: 16px;
}

.nm-ma-t-10 {
  margin-top: 10px;
}

.nm-ma-t-20 {
  margin-top: 20px;
}

.nm-ma-t--20 {
  margin-top: -20px;
}

.nm-ma-b-10 {
  margin-bottom: 10px;
}

.nm-ma-r-15 {
  margin-right: 15px;
}

.nm-ma-l-15 {
  margin-left: 15px;
}

.nm-ma-b-15 {
  margin-bottom: 15px;
}
