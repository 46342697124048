.nm-bo-n {
  border: none;
}

.nm-bo-ra-24 {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
}

.nm-bo-ra-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
