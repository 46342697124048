.nm-op-01 {
  opacity: 0.1;
}

.nm-op-3 {
  opacity: 0.3;
}

.nm-op-5 {
  opacity: 0.5;
}

.nm-ov-x-a {
  overflow-x: auto;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.nm-tr-tr-z {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.nm-ob-fi-sc-do {
  object-fit: scale-down;
}

.nm-ov-h {
  overflow: hidden;
}

.nm-ov-h-i {
  overflow: hidden !important;
}

.nm-ov-x-h {
  overflow-x: hidden;
}

.nm-ov-y-h {
  overflow-y: hidden;
}

.nm-di-g-i {
  display: grid !important;
}

.nm-di-n {
  display: none;
}

.nm-di-i-b {
  display: inline-block;
}

.po-n {
  pointer-events: none !important;
}

.nm-cu-p {
  cursor: pointer;
}

.nm-poi-ev-n {
  pointer-events: none;
}

.nm-di-web-box {
  display: -webkit-box;
}

.nm-web-l-cl {
  -webkit-line-clamp: 3
}

@media (max-width: 960px) {
  .nm-ov-y-h-ma-wi-960 {
    overflow: hidden;
  }
}
