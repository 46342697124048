.nm-tr-51e {
  -moz-transition: all .51s;
  -o-transition: all .51s;
  -webkit-transition: all .51s;
  transition: all .51s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.nm-tr-r {
  -webkit-transition: fill 450ms ease-in-out;
  -moz-transition: fill 450ms ease-in-out;
  -o-transition: fill 450ms ease-in-out;
  transition: fill 450ms ease-in-out;
}

.nm-optimize {
  -webkit-transform: translateZ(0) !important;
  -moz-transform: translateZ(0) !important;
  -ms-transform: translateZ(0) !important;
  -o-transform: translateZ(0) !important;
  transform: translateZ(0) !important;

  -webkit-backface-visibility: hidden !important;
  -moz-backface-visibility: hidden !important;
  -ms-backface-visibility: hidden !important;
  backface-visibility: hidden !important;

  -webkit-perspective: 1000 !important;
  -moz-perspective: 1000 !important;
  -ms-perspective: 1000 !important;
  perspective: 1000 !important;
}

.nm-optimize-image {
  image-rendering: optimizeSpeed; /* STOP SMOOTHING, GIVE ME SPEED  */
  image-rendering: -moz-crisp-edges; /* Firefox                        */
  image-rendering: -o-crisp-edges; /* Opera                          */
  image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
  image-rendering: pixelated; /* Chrome */
  image-rendering: optimize-contrast; /* CSS3 Proposed                  */
  -ms-interpolation-mode: nearest-neighbor; /* IE8+                           */

}

.nm-op-5 {
  opacity: .5;
}
