@import "./nm/animations.less";
@import "./nm/badge.less";
@import "./nm/border.less";
@import "./nm/colors.less";
@import "./nm/content.less";
@import "./nm/dimensions.less";
@import "./nm/form-elements.less";
@import "./nm/margin.less";
@import "./nm/outline.less";
@import "./nm/padding.less";
@import "./nm/position.less";
@import "./nm/shadows.less";
@import "./nm/text.less";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, Helvetica, sans-serif;
}

html, body {
  min-width: 320px;
  color: rgba(0,0,0,0.87);
  background-color: rgb(250,250,250);
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

@font-face {
  font-family: 'robotoregular';
  src: url('./fonts/Roboto-Regular-webfont.eot');
  src: url('./fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./fonts/Roboto-Regular-webfont.woff') format('woff'),
  url('./fonts/Roboto-Regular-webfont.ttf') format('truetype'),
  url('./fonts/Roboto-Regular-webfont.svg#robotoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons', serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.mat-dialog-container .mat-dialog-actions {
  margin: 0 -16px -24px -16px;
}

.mat-dialog-container .mat-dialog-actions .mat-button.mat-button-base.mat-primary {
  font-weight: 600;
  text-transform: uppercase !important;
}
