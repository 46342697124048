.nm-fo-s-13 {
  font-size: 13px;
}

.nm-fo-s-15 {
  font-size: 15px;
}

.nm-fo-s-16 {
  font-size: 16px;
}

.nm-fo-s-20 {
  font-size: 20px;
}

.nm-fo-s-34 {
  font-size: 34px;
}

.md-title.nm-fo-w-bold {
  font-weight: bold;
}

.nm-fo-w-400 {
  font-weight: 400;
}

.nm-fo-w-500 {
  font-weight: 500;
}

.nm-te-o-e {
  text-overflow: ellipsis;
}

.nm-te-a-c {
  text-align: center;
}

.nm-te-a-l {
  text-align: left;
}

.nm-te-a-j {
  text-align: justify;
}

.nm-te-a-r {
  text-align: right;
}

.nm-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.nm-le-s-005-em {
  letter-spacing: .005em;
}

.nm-li-h-24 {
  line-height: 24px;
}

.nm-li-h-40 {
  line-height: 40px;
}

@media (max-width: 960px) {
  .nm-fo-s-20_ma-wi-960 {
    font-size: 20px !important;
  }

  .nm-fo-s-16_ma-wi-960 {
    font-size: 16px !important;
  }

  .nm-fo-w-500_ma-wi-960 {
    font-weight: 400;
  }

  .nm-li-h-24_ma-wi-960 {
    line-height: 24px !important;
  }

  .nm-le-s-005-em_ma-wi-960 {
    letter-spacing: .005em;
  }

  .nm-le-s-01-em_ma-wi-960 {
    letter-spacing: .01em;
  }

  .nm-tr-te-o-e-ma-wi-960 {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
